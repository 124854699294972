<template>
  <vf-toggletip placement="bottom-end" data-test-id="image-search-tooltip" :props-trigger="{ ariaLabel: $t.searchImageLabel }">
    <template #trigger>
      <vf-icon name="objective" size="md" />
    </template>
    <template #default="{ close }">
      <div class="f-col text-center space-y-2">
        <h3 class="title-4 c-brand-2">
          {{ $t.new }}!
        </h3>
        <div>
          <h3 class="title-4 ">
            {{ $t.visualSearch }}
          </h3>
          <p class="mb-2">
            {{ $t.takeAPhoto }}
          </p>
        </div>
        <vf-button
          :loading="!syteReady"
          size="xs"
          class="--syte-start-camera-upload"
          data-camera-button-placement="search-bar"
        >
          {{ $t.startNow }}
        </vf-button>
        <vf-link class="mono cursor-pointer " size="sm" @click="close">
          {{ $t.close }}
        </vf-link>
      </div>
    </template>
  </vf-toggletip>
</template>

<script setup lang="ts">
const { ready: syteReady } = useSyte()
</script>
